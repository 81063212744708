<template>
  <div class="NotificationsTelegram">
    <div class="top">
      <div class="title">Telegram Notifications</div>
    </div>
    <div class="card">
      <div class="card-body">
        <telegram-notifications :monitor="monitor" :general="general"/>
      </div>
    </div>
  </div>
</template>

<script>
import TelegramNotifications from '@/components/Monitor/Notifications/Telegram.vue'

export default {
  components: {
    TelegramNotifications
  },

  metaInfo: {
    title: 'Telegram Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
  .NotificationsTelegram {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
</style>
