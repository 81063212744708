<template>
  <div class="Telegram">
    <p>All you need in order to enable telegram notifications is:</p>
    <ul>
      <li>Install <a href="https://telegram.org/" _target="blank">Telegram</a></li>
      <li>Choose whether you want to add pulsetic bot to group chat or private chat</li>
    </ul>

    <div class="switcher">
      <div class="case">
        <a :href="groupLink"
           target="_blank"
           class="link mb-2">Add to a telegram group</a>
        <p>If you want to add notifications to a <strong>telegram group</strong>, for example your team chat</p>
      </div>
      <div class="case">
        <a :href="privateLink"
           target="_blank"
           class="link mb-2">Add to a private chat</a>
        <p>If you want to add notifications to a <strong>private chat</strong>, meaning the bot will notify only you</p>
      </div>
    </div>

    <base-alert type="warning">
      Do not forget to click <strong>start</strong>, otherwise the bot will not work
    </base-alert>
    <template v-if="connected">
      <div v-if="groups" style="max-width: 850px;">
        <div v-for="group in groups" :key="group['group_title']">
          <p>You are connected to Telegram on group <strong>{{group['group_title']}}</strong>. <base-button style="padding: 0 5px; height: 20px; display: inline; background-color: #f08c32" @click="leaveTelegram(group['group_title'], group['telegram_chat_id'])">Leave</base-button></p>
        </div>
      </div>
      <div v-if="private_chats" style="max-width: 850px;">
        <div v-for="private_chat in private_chats" :key="private_chat['username']">
          <p>You are connected to Telegram private chat as <strong>{{private_chat['username']}}</strong>. <base-button style="padding: 0 5px; height: 20px; display: inline; background-color: #f08c32" @click="leaveTelegram(private_chat['username'], private_chat['private_chat_id'])">Leave</base-button></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import profileApi from '@/api/profileApi'
import BaseButton from '../../Base/BaseButton'
import swal from 'sweetalert2'
import { mapState } from 'vuex'

const botId = process.env.VUE_APP_TELEGRAM_BOT_ID
export default {
  components: { BaseButton },
  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    groupLink () {
      return `https://t.me/${botId}?startgroup=${this.monitor ? this.monitor.telegram_hash : this.user.telegram_hash}`
    },

    privateLink () {
      return `https://t.me/${botId}?start=${this.monitor ? this.monitor.telegram_hash : this.user.telegram_hash}`
    },

    ...mapState('authentication', [
      'user'
    ])
  },

  data () {
    return {
      connected: false,
      groups: [],
      private_chats: []
    }
  },

  created () {
    this.checkIfAlreadyConnected()
  },

  methods: {
    async checkIfAlreadyConnected () {
      try {
        let response = null
        if (this.monitor) {
          response = await monitorsApi.checkTelegram(this.monitor.id)
        } else {
          response = await profileApi.checkTelegram()
        }
        if (response) {
          this.connected = true
          response.forEach((element) => {
            if (element['type'] === 'group') {
              this.groups.push({
                'group_title': element['group_title'],
                'telegram_chat_id': element['telegram_chat_id']
              })
            } else if (element['type'] === 'private') {
              this.private_chats.push({
                'username': element['username'],
                'private_chat_id': element['telegram_chat_id']
              })
            }
          })
        }
      } catch {
        console.error('Error checking telegram connection')
      }
    },

    async leaveTelegram (chat_name, chat_id) {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to leave this telegram chat: ' + chat_name + ' ?' + '</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Leave',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          try {
            if (!this.monitor) {
              await profileApi.leaveTelegramChat(chat_id)
            } else {
              await monitorsApi.leaveTelegramChat(this.monitor.id, chat_id)
            }
            window.location.reload()
          } catch (e) {
            alert('Something went wrong!')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .Telegram {
    max-width: 550px;
    color: #777;
    line-height: 1.5;

    p {
      margin-bottom: 4px;
    }

    ul {
      margin-top: 0;
    }

    .description {
      margin-bottom: 10px;
    }

    .switcher {
      display: flex;

      .case {
        padding: 10px;
        width: 300px;
      }
    }

    .link {
      @include inline-flex-center;

      height: 36px;
      padding: 7px 20px;

      font-size: 13px;
      font-weight: 600;
      cursor: pointer;

      border-radius: 4px;
      border: none;
      transition: 0.3s background-color;

      outline: none;

      background-color: map-get($colors, purple-1);
      color: #fff;

      &:hover {
        background-color: darken(map-get($colors, purple-1), 10%);
      }
    }
  }
</style>
